import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation} from 'react-router-dom';

import { 
  authContextHelper,
  types,
  helpers
} from 'utilities';



// lazy load all the views

// home
const Home = React.lazy(() => import('../pages/generic/Home'));
const Dashboard = React.lazy(() => import('../pages/generic/Dashboard'));
const ProductWindow = React.lazy(() => import('../pages/generic/ProductWindow'));
const Profile = React.lazy(() => import('../pages/account/Profile'));
const Settings = React.lazy(() => import('../pages/account/Settings'));
const Support = React.lazy(() => import('../pages/account/Support'));
const Authenticate = React.lazy(() => import('../pages/auth/Authenticate'));
// const TestProductPage = React.lazy(() => import('./TestProductPage'));
/**
 * Admin Components
 */
const AdminDashboard = React.lazy(() => import('../pages/admin/Dashboard'));
const AdminUsers = React.lazy(() => import('../pages/admin/Users'));
const AdminDash = React.lazy(() => import('../pages/admin/Dash'));

const loading = () => <div className=""></div>;


const SuspenseLoader = ({ component: Component, setShowTopSearchBar }: types.IntSuspenseLoader & {setShowTopSearchBar?: any}) => {
  // const location = useLocation();

  return (
    <Suspense fallback={loading()}>
      <Component setShowTopSearchBar={setShowTopSearchBar} />
    </Suspense>
  )
};


const RouteDefinitions: React.FC<types.IntRouteDefinitions> = ({ setShowTopSearchBar }) => (
  <Routes>
    <Route path="/authenticate" element={
      <SuspenseLoader component={Authenticate} setShowTopSearchBar={setShowTopSearchBar} />
    } 
    />

    {/* <Route path="/products" element={
      <LoadComponent component={TestProductPage} />
    }
    /> */}

    <Route path="/dashboard" element={
      <authContextHelper.EnsureSignedIn>
        <SuspenseLoader component={Dashboard} setShowTopSearchBar={setShowTopSearchBar} />
      </authContextHelper.EnsureSignedIn>
    } 
    />

    <Route path="/product-window" element={
      <authContextHelper.EnsureSignedIn>
        <SuspenseLoader component={ProductWindow} setShowTopSearchBar={setShowTopSearchBar} />
      </authContextHelper.EnsureSignedIn>
    } 
    />

    <Route path="/profile" element={
      <authContextHelper.EnsureSignedIn>
        <SuspenseLoader component={Profile} setShowTopSearchBar={setShowTopSearchBar}/>
      </authContextHelper.EnsureSignedIn>
    }/>

    <Route path="/settings" element={
      <authContextHelper.EnsureSignedIn>
        <SuspenseLoader component={Settings} setShowTopSearchBar={setShowTopSearchBar}/>
      </authContextHelper.EnsureSignedIn>
    }/>

    <Route path="/support" element={
      <authContextHelper.EnsureSignedIn>
        <SuspenseLoader component={Support} setShowTopSearchBar={setShowTopSearchBar}/>
      </authContextHelper.EnsureSignedIn>
    }/>

    <Route path="/admin/dash" element={
      <authContextHelper.EnsureAdminSignedIn>
        <SuspenseLoader component={AdminDash} setShowTopSearchBar={setShowTopSearchBar}/>
      </authContextHelper.EnsureAdminSignedIn>
    }/>

    <Route path="/" element={
      <authContextHelper.EnsureSignedIn>
        <SuspenseLoader component={Home} setShowTopSearchBar={setShowTopSearchBar}/>
      </authContextHelper.EnsureSignedIn>
    }/>

    {/*<Route path="/" element={<LoadComponent component={Home} setShowTopSearchBar={setShowTopSearchBar}/>}/>*/}

    

    <Route path="/*" element={<Navigate to="/authenticate" replace />}/>
  </Routes>
        
);


export default RouteDefinitions;