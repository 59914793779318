import React, { useState } from 'react';
import { BrowserRouter} from 'react-router-dom';

// components
import {
  Footermain,
  Topnav 
} from 'components'
import { authContextHelper } from 'utilities';
import RouteDefinitions  from './RouteDefinitions';


const Routes: React.FC = () => {
  const [showSearchBar, setShowTopSearchBar] = useState(false);

  return (
    <>
      <BrowserRouter>
        <authContextHelper.AuthProvider>
          <div className="app">
            
            <Topnav
              navClass="bg-white navbar-light k-z-ind-10"
              isSticky={true}
              showTopSearchBar={showSearchBar}
            />
            
            <div className='k-fg-1 '>
              <RouteDefinitions  setShowTopSearchBar={setShowTopSearchBar} />
            </div>
            <Footermain />
          </div>
        </authContextHelper.AuthProvider>
      </BrowserRouter>
    </>
  );
};

export default Routes;
