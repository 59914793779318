import React, { useState } from 'react';
import { Form } from 'react-bootstrap'
import { types } from 'utilities';

export const AdminGroupAddUsersDropdownToggle: React.FunctionComponent = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <div
    style={{cursor: 'pointer'}}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ div>
));


export const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {
            React.Children.toArray(children).filter(
              (child : any) => {
                return !value.toLowerCase() || child.props.children.toLowerCase().includes(value.toLowerCase())
              },
            )
          }
        </ul>
      </div>
    );
  },
); 

export const AdminGroupAddUsersDropdownMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="w-auto"
          //style={{border: 'none'}}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <div style={{ maxHeight: '100px', overflowX: 'auto'}}>
          {
            React.Children.toArray(children).filter(
              (child: any) => {
                return !value.toLowerCase() || child.props.children.toLowerCase().includes(value.toLowerCase())
              },
            )
          }
        </div>
      </div>
    );
  },
);


export const UserInitials: React.FC<types.IntUserInitials> = ({ input, backgroundColor = 'black', width = '25px', height = '25px', fontSize = '12px', border = 'none', color = 'white' }) => {
  // Extracting the first two letters from the input
  const initials = input ? input.substr(0, 2).toUpperCase() : 'GU';

  return (
    <div style={{
      backgroundColor: backgroundColor,
      color: color,
      borderRadius: '50%',
      width: width,
      height: height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
      border: border,
    }}>
      <span style={{ fontSize: fontSize }}>{initials}</span>
    </div>
  );
};


export const SelectGroupDropdownToggle: React.FunctionComponent = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <div
    style={{cursor: 'pointer'}}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </ div>
));

export const SelectGroupDropdownMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="w-auto pt-0 k-inputText12"
          style={{border: 'none'}}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <hr className="m-0"/>
        <div className="k-mh-200 k-of-x-auto k-inputText12">
          {
            React.Children.toArray(children).filter(
              (child: any) => {
                return !value.toLowerCase() || child.props.children.toLowerCase().includes(value.toLowerCase())
              },
            )
          }
        </div>
      </div>
    );
  },
);